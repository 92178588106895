import React from 'react'

function MenuItems({menuItem}) {
    return (
        <div className="portfolis">
            {
                menuItem.map((item)=>{
                   return ChecklinkSize(item) 
                })
            }
        </div>
    )

}

function ChecklinkSize(item){
    // console.log(item)
    if(item.link2){
    return <div className="portfolio" key={item.id}>
    <div className="image-data">
        <img src={item.image} alt=""/>
        <ul className="hover-items">
            <li>
                <a target="popup" href={item.link1}>{item.icon1}</a>
                <a target="popup" href={item.link2}>{item.icon2}</a>
            </li>
        </ul>
    </div>
    <h5>
        {item.title}
    </h5>
    <p>{item.description}</p>
    </div>
    }
    else{
        return <div className="portfolio" key={item.id}>
        <div className="image-data">
            <img src={item.image} alt=""/>
            <ul className="hover-items">
                <li>
                    <a target="popup" href={item.link1}>{item.icon1}</a>
                </li>
            </ul>
        </div>
        <h5>
            {item.title}
        </h5>
        <p>{item.description}</p>
        </div>  
    }
}

export default MenuItems;
