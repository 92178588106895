import React from 'react'
import Tittle from '../Components/Tittle'
import ImageSection from '../Components/ImageSection';
import SkillsSection from '../Components/SkillsSection';
import ServicesSection from '../Components/ServicesSection';
import design from '../img/design.svg';
import intelligence from '../img/intelligence.svg';
import helpdesk from '../img/helpdesk.svg';

function AboutPage() {
    return (
        <div className="AboutPage">
            
            <ImageSection />
            <br></br>
            <Tittle title={'My Skills'} span={'My Skills'} />
            <div className="skillsContainer">
                <SkillsSection skill={'Javascript'} progress={'90%'} width={'90%'} />
                <SkillsSection skill={'Typescript'} progress={'30%'} width={'30%'} />
                <SkillsSection skill={'React Js'} progress={'70%'} width={'70%'} />
                <SkillsSection skill={'Node Js'} progress={'40%'} width={'40%'} />
                <SkillsSection skill={'Python'} progress={'50%'} width={'50%'} />
                <SkillsSection skill={'Java'} progress={'80%'} width={'80%'} />
                <SkillsSection skill={'c++'} progress={'70%'} width={'70%'} />
                <SkillsSection skill={'c#'} progress={'60%'} width={'60%'} />
                <SkillsSection skill={'Html'} progress={'90%'} width={'90%'} />

            </div>
            <br></br>
            <Tittle title={'Services'} span={'Services'} />
            <div className="servives-container">
                <ServicesSection image={design} title={'Web development'} 
                text={'Software developer with experince in the Industry'}
                />
                <ServicesSection image={intelligence} title={'Project Manager'} 
                text={'Have Lead Software Development Projects'}
                />
                <ServicesSection image={helpdesk} title={'Helpdesk'} 
                text={'Experinced Technician servicing Corperate Staff'}
                />
                

                
            </div>

        </div>
    )
}

export default AboutPage;
