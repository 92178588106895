import React from 'react'
import ContactItem from '../Components/ContactItem';
import phone from '../img/phone.svg';
import email from '../img/emailme.svg';
import location from '../img/location.svg';
import Tittle from '../Components/Tittle';

function ContactPage() {
    return (
        <div>
            <div className="title">
                <Tittle title={'Contact Details'} span={'About Me'} />
            </div>
            <div className="ContactPage">
                <div className="contact-sect">
                    <ContactItem icon={phone} text1={'0408686388'} title={'Mobile'}/>
                    <ContactItem icon={email} text1={'tyronedeza@gmail.com'} text2={'deza41@hotmail.com'} title={'Email'}/>
                    <ContactItem icon={location} text1={'NSW'} text2={'Australia'} title={'Newcastle'}/>
                </div>
            </div>
        </div>
    )
}

export default ContactPage;
