import React from 'react'
import "../styles/WorkPage.css"

import { ReactComponent as WorkIcon } from "../img/work.svg"
import { ReactComponent as SchoolIcon } from "../img/school.svg"
import timelineElements from "../Components/timelineElements";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function WorkPage() {

    let workIconStyles = { background: "#06D6A0" };
    let schoolIconStyles = { background: "#f9c74f" };

    return (
        <div>
      <h1 className="title3">Timeline</h1>
      <VerticalTimeline>
        {timelineElements.map((element) => {
            console.log(element);
          let isWorkIcon = element.icon === "work";
          let showButton =
            element.buttonText !== undefined &&
            element.buttonText !== null &&
            element.buttonText !== "";

          return (
            <VerticalTimelineElement
              key={element.key}
              date={element.date}
              dateClassName="date"
              iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
              icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
            >
              <div className="text-color-set">
                <h3 className="vertical-timeline-element-title">
                  {element.title}
                </h3>
                <br></br>
                <img className="work-icon" src={element.workIcon} alt=""/>
                <h5 className="vertical-timeline-element-subtitle">
                  {element.location}
                </h5>
                <p id="description">{element.description}</p>
              </div>
              {showButton && (
                <a
                  className={`button ${
                    isWorkIcon ? "workButton" : "schoolButton"
                  }`}
                  href={element.url} target="popup"
                >
                  {element.buttonText}
                </a>
              )}
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
    )
}

export default WorkPage;
