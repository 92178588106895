import Lionhouse from "../img/Lionhouse.png";
import Newcastle from "../img/newcastle.png";
import ICC from "../img/ICC.svg";
import Komatsu from "../img/komatsu.png";
import Arrowtail from "../img/arrowtail.png";

let timelineElements = [
  {
    id: 1,
    title: "Software Engineer",
    location: "Arrowtail",
    description:
      "Worked on the Arrowtail Platform, a platform for managing and reporting mining related data and processes (ERP).",
    buttonText: "Find out More",
    date: "April 2022 - Present",
    icon: "work",
    workIcon: Arrowtail,
    url: "https://lionhouse.com.au/",
  },
  {
    id: 2,
    title: "Software Engineer",
    location: "Komatsu",
    description:
      "Worked on the Smart Care Product, a predictive maintenance solution for Komatsu's mining equipment. Fullstack product for managing and analysing data from mining equipment.",
    buttonText: "Find out More",
    date: "June 2021 - April 2022",
    icon: "work",
    workIcon: Komatsu,
    url: "https://www.komatsu.com.au/",
  },
  {
    id: 3,
    title: "Software Solutions Manager",
    location: "Lion House",
    description:
      "Team lead of a small development team, developing software solutions for industry clients. Built student management and reporting systems for the RTO sector.",
    buttonText: "Find out More",
    date: "December 2019 - May 2021",
    icon: "work",
    workIcon: Lionhouse,
    url: "https://lionhouse.com.au/",
  },
  {
    id: 4,
    title: "Bachelor of Computer Science",
    location: "University of Newcastle",
    description:
      "Batchelor Degree in Computer Science studied at the University of Newcastle.",
    buttonText: "View Certificate",
    date: "2014 - 2019",
    icon: "school",
    workIcon: Newcastle,
    url: "https://wgsbmmzmwrkyokksawcp.supabase.in/storage/v1/object/sign/file.uploads/Document.pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmaWxlLnVwbG9hZHMvRG9jdW1lbnQucGRmIiwiaWF0IjoxNjMxNTE0MDg3LCJleHAiOjE5NDY4NzQwODd9.goHLCiPgzPR312JPD6sfMg3LGgz0C6tIYO5h3EvVD1U",
  },
  {
    id: 5,
    title: "Web Developer",
    location: "ICC OOSH",
    description:
      "Developed custom wordpress site with booking system. Met with the client at development intivals to discuss changes.",
    date: "June 2018 - July 2018",
    icon: "work",
    workIcon: ICC,
  },

];

export default timelineElements;
