// import port1 from '../img/portImages/maya-1.jpg';
// import port2 from '../img/portImages/port1.jpg';
// import port3 from '../img/portImages/port2.jpg';
// import port4 from '../img/portImages/port3.jpg';
// import port6 from '../img/portImages/port5.jpg';
// import port7 from '../img/portImages/port6.jpg';
 import skilledcare from '../img/skilled-care.jpg';
 import fit from '../img/fit.jpg';
 import myfitcareer from '../img/myfitcareer.jpg';


// portfolio objects

const portfolios = [
    {
        id: 1,
        category: 'Full Stack',
        link1: 'https://github.com/deza41/secret-chat-socket-io-frontend',
        link2: 'https://secretchat-socket-io.netlify.app/',
        icon1: 'Git',
        icon2: 'Web',
        image: 'https://wgsbmmzmwrkyokksawcp.supabase.in/storage/v1/object/sign/file.uploads/162701928939705623.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmaWxlLnVwbG9hZHMvMTYyNzAxOTI4OTM5NzA1NjIzLnBuZyIsImlhdCI6MTYzMTUxNDA4NiwiZXhwIjoxOTQ2ODc0MDg2fQ.isCz8FMP1GySgKmphIOHUm6JSu-BLMftmOk8CKvwZ7s',
        title: 'Secret Chat',
        description: "React, Node, Socket.io"
    },
    {
        id: 2,
        category: 'React',
        link1: 'https://github.com/deza41/weather-API-React',
        link2: 'https://zen-heyrovsky-8205d2.netlify.app/',
        icon1: 'Git',
        icon2: 'Web',
        image: 'https://wgsbmmzmwrkyokksawcp.supabase.in/storage/v1/object/sign/file.uploads/weather api resize?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmaWxlLnVwbG9hZHMvd2VhdGhlciBhcGkgcmVzaXplIiwiaWF0IjoxNjMxNTE0MzYzLCJleHAiOjE5NDY4NzQzNjN9.ct62mSzhOb_anRCQMIChVXysui9S2Bsd_tYzntANc8k',
        title: 'weather API',
        description: "React, Axios"
    },
    {
        id: 3,
        category: 'React',
        link1: 'https://github.com/deza41/Rick-Morty-Apollo',
        link2: 'https://quizzical-gates-9a0204.netlify.app/',
        icon1: 'Git',
        icon2: 'Web',
        image: 'https://wgsbmmzmwrkyokksawcp.supabase.in/storage/v1/object/sign/file.uploads/rick.gif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmaWxlLnVwbG9hZHMvcmljay5naWYiLCJpYXQiOjE2MzE1MTQzNzYsImV4cCI6MTk0Njg3NDM3Nn0.elKhszMRvXmIEu4Lfbc5q2JE_755DJ5RybcqUQ4WWso',
        title: 'Rick & Morty - Database',
        description: "React, GraphQL, Apollo"
    },
    {
        id: 4,
        category: 'React',
        link1: 'https://github.com/deza41/Space-X-CodingChallenge',
        link2: 'https://gallant-kowalevski-10b99c.netlify.app/',
        icon1: 'Git',
        icon2: 'Web',
        image: 'https://wgsbmmzmwrkyokksawcp.supabase.in/storage/v1/object/sign/file.uploads/spacex.gif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmaWxlLnVwbG9hZHMvc3BhY2V4LmdpZiIsImlhdCI6MTYzMTUxNDMyMiwiZXhwIjoxOTQ2ODc0MzIyfQ.Y_NAkgjZtbkRrOPpLRKYDgxonBT9l7gybCD99n3dgc0',
        title: 'Space-X: Coding Challenge',
        description: "React, GraphQL, Apollo"
    },
    {
        id: 5,
        category: 'Wordpress',
        link1: 'https://skilledcare.edu.au/',
        icon1: 'Web',
        image: skilledcare,
        title: 'Skilled Care - Website',
        description: "Wordpress, JS, Css"
    },
    {
        id: 6,
        category: 'React',
        link1: 'https://github.com/deza41/Task-Reminder-React.js',
        link2: 'https://gifted-kirch-ac5768.netlify.app',
        icon1: 'Git',
        icon2: 'Web',
        image: 'https://wgsbmmzmwrkyokksawcp.supabase.in/storage/v1/object/sign/file.uploads/task tracker.gif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmaWxlLnVwbG9hZHMvdGFzayB0cmFja2VyLmdpZiIsImlhdCI6MTYzMTUxNDQxNywiZXhwIjoxOTQ2ODc0NDE3fQ.xKJcfDz0ODVAJSPFw9ENGw7J7rDA2UnpjCnS8bKA4As',
        title: 'Task Reminder',
        description: "React, Rest, Supabase"
    },
    {
        id: 7,
        category: 'Wordpress',
        link1: 'https://myfitcareer.com.au/',
        icon1: 'Web',
        image: myfitcareer,
        title: 'My Fit Career - Website',
        description: "Wordpress, JS, Css"
    },
    {
        id: 8,
        category: 'Wordpress',
        link1: 'https://f-i-t.com.au/',
        icon1: 'Web',
        image: fit,
        title: 'Fit - Website',
        description: "Wordpress, JS, Css"
    },
    {
        id: 9,
        category: 'Java',
        link1: 'https://github.com/deza41/Connect-4-AI',
        icon1: 'Git',
        image: 'https://user-images.githubusercontent.com/15008435/124422864-5d541300-dda7-11eb-8ec2-751c131d0641.png',
        title: 'Connect-4-AI',
        description: "Java"
    },
    
]

export default portfolios;


