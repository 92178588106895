import React from 'react';
import {faGithub} from '@fortawesome/free-brands-svg-icons';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

function HomePage() {
    return (
        <div className="HomePage">
            <header className="hero">
                <h1 className="hero-text">
                    Hi, I am 
                    <span> Tyrone Deza</span>
                </h1>
                <p className="h-sub-text">
                    This is my personal portfolio website that contains my projects, work experience and personal details.
                </p>
                <div className="icons">
                    <Link to={{ pathname: "https://linkedin.com/in/tyrone-deza-a43497212/" }} target="_blank" className="icon-holder" >
                        <FontAwesomeIcon icon={faLinkedin} className="icon fb" />
                    </Link>
                    <Link to={{ pathname: "https://github.com/deza41" }} target="_blank" className="icon-holder" >
                        <FontAwesomeIcon icon={faGithub} className="icon gh" />
                    </Link>
                </div>
            </header>
        </div>
    )
}

export default HomePage;
