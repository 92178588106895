import React from 'react';
// import about from '../img/about.jpg';

function ImageSection() {
    return (
        <div className="ImageSection">
            {/* <div className="img">
                <img src={about} alt=""/>
            </div> */}
            <div className="about-info">
                <h4>I am<span> Tyrone Deza</span></h4>
                <p className="about-text">
                Forward-thinking Software Engineer with experience with leading development projects in the RTO
                sector. Developer who thrives with working with teams and stakeholders to meet the project
                requirements and demands.
                </p>
                <br></br>
                <div className="about-details">
                    <div className="left-section">
                        <p><b>Full Name</b></p>
                        <p><b>Age</b></p>
                        <p><b>Nationality</b></p>
                        <p><b>Languages</b></p>
                        <p><b>City</b></p>
                        <p><b>Education</b></p>
                    </div>
                    <div className="right-section">
                        <p>: Tyrone Deza</p>
                        <p>: 26</p>
                        <p>: Australian</p>
                        <p>: English, Tagalog</p>
                        <p>: Newcastle</p>
                        <p>: University of Newcastle</p>
                    </div>
                </div>
                <br></br>
                <a target="popup" href="https://wgsbmmzmwrkyokksawcp.supabase.in/storage/v1/object/sign/file.uploads/Tyrone_Deza_CV (2).pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmaWxlLnVwbG9hZHMvVHlyb25lX0RlemFfQ1YgKDIpLnBkZiIsImlhdCI6MTYzMTUxNDA4OCwiZXhwIjoxOTQ2ODc0MDg4fQ.HZvRegU6VdtdD-yYvtPC_S7GqUos165TzG9hm5BEIiI" className="btn">Download Cv</a>
            </div>
        </div>
    )
}

export default ImageSection;
